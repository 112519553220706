import { Controller } from 'stimulus';
import Swiper, { Autoplay, EffectFade } from 'swiper';
Swiper.use([Autoplay, EffectFade]);

export default class extends Controller {
  static targets = ['slider']

  connect() {
    this.initHeroSlider();
  }

  initHeroSlider() {
    new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      loop: true,
      speed: 600,
      effect: 'fade',
      autoHeight: true,
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      allowTouchMove: false,
    });
  }

  scrollDown() {
    const firstModule = document.querySelectorAll('section')[1];
    const firstModulePos = firstModule.getBoundingClientRect();
    const header = document.querySelector('.site__header');

    window.scrollTo({
      top: firstModulePos.top - (header.offsetHeight - 10),
      behavior: 'smooth',
    });
  }
}
